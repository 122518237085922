import React from 'react'
import { Link } from 'gatsby'
import project1 from '../../assets/images/projects/project1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import project3 from '../../assets/images/projects/project3.jpg'
import project4 from '../../assets/images/projects/project4.jpg'
import project5 from '../../assets/images/projects/project5.jpg'
import project6 from '../../assets/images/projects/project6.jpg'

// Functional components
const TwoColumns = () => {
    return (
        <div className="projects-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project1} alt="about" />

                                <Link to="/studii-de-caz/sistem-management-transport/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/sistem-management-transport/">
                                        Sistem de management în transport
                                    </Link>
                                </h3>
                                <span>Software la comandă</span>
                            </div>
                        </div>
                    </div>

                 {/*  <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project2} alt="about" />

                                <Link to="/studii-de-caz/retail-online" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/retail-online">
                                        Platformă de retail online
                                    </Link>
                                </h3>
                                <span>Testare software</span>
                            </div>
                        </div>
                    </div>*/}

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project3} alt="about" />

                                <Link to="/studii-de-caz/aplicatie-plati-mobile/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/aplicatie-plati-mobile/">
                                        Aplicație mobilă de plăți
                                    </Link>
                                </h3>
                                <span>Echipă dedicată</span>
                            </div>
                        </div>
                    </div>

                     <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project6} alt="about" />

                                <Link to="/studii-de-caz/inchirieri-auto/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/inchirieri-auto/">
                                        Închirieri auto
                                    </Link>
                                </h3>
                                <span>Dezvoltare de produs</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project5} alt="about" />

                                <Link to="/studii-de-caz/companie-aeriana/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/companie-aeriana/">
                                        Companie aeriană
                                    </Link>
                                </h3>
                                <span>Dezvoltare de produs</span>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box ">
                            <div className="image">
                                <img src={project5} alt="about" />

                                <Link to="/studii-de-caz/monitorizare-transport/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/monitorizare-transport/">
                                        Monitorizare transport maritim
                                    </Link>
                                </h3>
                                <span>Dezvoltare și migrare cloud</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-projects-box">
                            <div className="image">
                                <img src={project6} alt="about" />

                                <Link to="/studii-de-caz/erp-productie/" className="link-btn">
                                    <i className='bx bx-plus'></i>
                                </Link>
                            </div>

                            <div className="content">
                                <h3>
                                    <Link to="/studii-de-caz/erp-productie/">
                                        ERP producție industrială
                                    </Link>
                                </h3>
                                <span>Transformare Digitală</span>
                            </div>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </div>
    )
}

export default TwoColumns